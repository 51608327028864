import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IFlexposCustomer,
	IJournalLine1,
} from '@ameroservices-platform/shipfromstore-flexposui/app/main/apps/flexposShipping/types/flexpos';
import {
	IShippingInfo,
	IShippingState,
	ParsedFlexposJournalLine,
	ParsedProductLineIncludingDiscount,
} from '@ameroservices-platform/shipfromstore-flexposui/app/main/apps/flexposShipping/types/shippingStateTypes';
import { cloneDeep } from 'lodash';
import { PPrint } from '@ameroservices-platform/shipfromstore-flexposui/app/services/Util';

export const defaultFlexposUIDValue = '00000000-0000-0000-0000-000000000000';

const initialState: IShippingState = {
	journalUid: null,
	journalLines: [],
	shippingInfo: {} as IShippingInfo,
	selectedShippingMethod: null,
	selectedDropPoint: null,
	activeStep: 0,
	firestoreFlexposProducts: {},
	error: '',
	loading: false,
	stock: null,
	orderRef: null,
	orderForUse: null,
	customer: null,
	debug: null,
	writeToPos: false,
};

const parseFlexPosJournalLine = (line: IJournalLine1): ParsedFlexposJournalLine => {
	return {
		productNumber: line.ProdNo,
		uid: line.UID,
		price: line.Price,
		amount: line.Am,
		campaignUid: line.CampaignUID,
		journalLineID: line.JournalLineID,
		deleted: line.deleted,
		desc: line.ShortDescr,
		qty: line.Quntity,
		lineType: line.LnTy,
	};
};

export const processJournalDataFromFlexPos = (journalLines) => {
	// Stage 1: They're still journal lines
	const parsedFlexposJournalLinesExcludingCampaignDiscountLines: ParsedFlexposJournalLine[] = journalLines
		.reduce((arr, line) => {
			if (line.LnTy === 2 && line.CampaignUID !== defaultFlexposUIDValue) {
			} else {
				arr.push(line);
			}
			return arr;
		}, [])
		.map((line) => parseFlexPosJournalLine(line));

	let fromIndex = parsedFlexposJournalLinesExcludingCampaignDiscountLines.findIndex(
		(line) => line.productNumber === 'info' && line.desc.toLowerCase().includes('sendes efterfølgende')
	);
	let toIndex = parsedFlexposJournalLinesExcludingCampaignDiscountLines.findIndex(
		(line) => line.productNumber === 'info' && line.desc.toLowerCase().includes('med nu')
	);

	fromIndex = fromIndex !== -1 ? fromIndex : 0;
	toIndex = toIndex !== -1 ? toIndex : 0;

	// Stage 2: We're parsing the into product lines including discount
	let productLinesIncludingTheirDiscounts = [];

	// Handle lines currently in after-segment
	const afterSegment = parsedFlexposJournalLinesExcludingCampaignDiscountLines.slice(
		fromIndex !== -1 ? fromIndex : 0,
		toIndex !== -1 ? toIndex : 0
	);
	if (afterSegment.length > 0) {
		afterSegment.forEach((line) => {
			if (line.lineType === 2) {
				const refIndex = productLinesIncludingTheirDiscounts.findIndex(
					(journalLine) => journalLine.productNumber === line.productNumber
				);
				productLinesIncludingTheirDiscounts[refIndex].afterDiscount = {
					desc: line.desc,
					discountPricePerUnit: line.amount / productLinesIncludingTheirDiscounts[refIndex].afterQty,
				};
			}
			if (line.lineType === 1) {
				productLinesIncludingTheirDiscounts.push({
					productNumber: line.productNumber,
					uid: line.uid,
					price: line.price,
					campaignUid: line.campaignUid,
					journalLineID: line.journalLineID,
					deleted: line.deleted,
					desc: line.desc,
					afterQty: line.qty,
					nowQty: 0,
					lineType: line.lineType,
					nowDiscount: null,
					afterDiscount: null,
				});
			}
		});
	}

	// Handle lines currently in now-segment
	const nowSegment = parsedFlexposJournalLinesExcludingCampaignDiscountLines.slice(toIndex);
	if (nowSegment.length > 0) {
		nowSegment.forEach((line) => {
			const currentIndex = productLinesIncludingTheirDiscounts.findIndex(
				(tempLine) => tempLine.productNumber === line.productNumber
			);
			const doesProductAlreadyExistInProductLinesIncludingTheirDiscounts = currentIndex !== -1;

			if (doesProductAlreadyExistInProductLinesIncludingTheirDiscounts === false && line.lineType === 1) {
				productLinesIncludingTheirDiscounts.push({
					productNumber: line.productNumber,
					uid: line.uid,
					price: line.price,
					campaignUid: line.campaignUid,
					journalLineID: line.journalLineID,
					deleted: line.deleted,
					desc: line.desc,
					afterQty: 0,
					nowQty: line.qty,
					lineType: line.lineType,
					nowDiscount: null,
					afterDiscount: null,
				});
			}

			if (doesProductAlreadyExistInProductLinesIncludingTheirDiscounts === true) {
				if (line.lineType === 1)
					productLinesIncludingTheirDiscounts[currentIndex].nowQty =
						productLinesIncludingTheirDiscounts[currentIndex].nowQty + line.qty;
				if (line.lineType === 2)
					productLinesIncludingTheirDiscounts[currentIndex].nowDiscount = {
						desc: line.desc,
						discountPricePerUnit: line.amount / productLinesIncludingTheirDiscounts[currentIndex].nowQty,
					};
			}
		});
	}
	return productLinesIncludingTheirDiscounts;
};

const shippingSlice = createSlice({
	name: 'shippingSlice',
	initialState,
	reducers: {
		setJournalLines: (state, action: PayloadAction<Array<IJournalLine1>>) => {
			state.journalLines = processJournalDataFromFlexPos(action.payload);
			state.loading = false;
		},
		setJournalUid: (state, action: PayloadAction<string>) => {
			state.journalUid = action.payload;
		},
		setNowAmount: (state, action: PayloadAction<{ uid: string; amount: number }>) => {
			state.journalLines = state.journalLines.map((line) => {
				if (line.uid === action.payload.uid) {
					line.nowQty = action.payload.amount;
				}
				return line;
			});
		},
		setAfterAmount: (state, action: PayloadAction<{ uid: string; amount: number }>) => {
			state.journalLines = state.journalLines.map((line) => {
				if (line.uid === action.payload.uid) {
					line.afterQty = action.payload.amount;
				}
				return line;
			});
		},
		switchAmount: (state, action: PayloadAction<{ uid: string }>) => {
			const index = state.journalLines.findIndex((line) => line.uid === action.payload.uid);

			const journalLineDeepClone = cloneDeep(state.journalLines[index]);

			const remoteStockForProduct = state.stock[journalLineDeepClone.productNumber].stock;

			if (remoteStockForProduct < journalLineDeepClone.nowQty) {
				journalLineDeepClone.nowQty = remoteStockForProduct;
			}

			state.journalLines[index] = {
				...state.journalLines[index],
				// swap qty now <-> after
				nowQty: journalLineDeepClone.afterQty,
				afterQty: journalLineDeepClone.nowQty,
				// swap discountObj now <-> after
				afterDiscount: journalLineDeepClone.nowDiscount,
				nowDiscount: journalLineDeepClone.afterDiscount,
			};
		},
		setShippingInfoFormData: (state, action: PayloadAction<{ [name: string]: string | number }>) => {
			const formDataByKeyValue = Object.entries(action.payload);

			let form = {};
			formDataByKeyValue.forEach(([key, value]) => {
				form[key] = value.toString();
			});

			state.shippingInfo = {
				...state.shippingInfo,
				...form,
			};
		},
		setShippingMethod: (state, action: PayloadAction<Record<string, string>>) => {
			state.selectedShippingMethod = action.payload;
		},
		setDropPoint: (state, action: PayloadAction<Record<string, string>>) => {
			state.selectedDropPoint = action.payload;
		},
		setActiveStep: (state, action: PayloadAction<number>) => {
			state.activeStep = action.payload;
		},
		setStock: (state, action: PayloadAction<string>) => {
			state.stock = JSON.parse(action.payload);
		},
		setStockByProductNumber: (state, action: PayloadAction<{ productNumber: string; stock: number }>) => {
			if (!state.stock) {
				state.stock = {};
			}
			state.stock[action.payload.productNumber] = { stock: action.payload.stock };
		},
		setShippingInfoFromFlexposCustomer: (state, action: PayloadAction<IFlexposCustomer>) => {
			const { CustomerAdr, CustomerCity, CustomerEmail, CustomerName, CustomerZipCode, CustomerTlf } =
				action.payload;

			let lastname = '';
			let firstname = '';
			if (CustomerName) {
				const customerNameSplit = CustomerName.split(' ');
				if (customerNameSplit.length > 0) {
					lastname = customerNameSplit.pop();
					firstname = customerNameSplit.join(' ');
				}
			}

			state.shippingInfo = {
				firstname: firstname || '',
				lastname: lastname || '',
				address: CustomerAdr || '',
				postalCode: CustomerZipCode || '',
				city: CustomerCity || '',
				phone: CustomerTlf || '',
				email: CustomerEmail || '',
			};
		},
		setOrderRef: (state, action: PayloadAction<Record<string, any>>) => {
			state.orderRef = action.payload;
		},
		setOrderForUse: (state, action: PayloadAction<Record<string, any>>) => {
			state.orderForUse = action.payload;
		},
		setDebug: (state, action: PayloadAction<any>) => {
			state.debug = action.payload;
		},
		setWriteToPOS: (state) => {
			state.writeToPos = true;
		},
		setDoneWriteToPOS: (state) => {
			state.writeToPos = false;
		},
		setFirestoreFlexposProducts: (state, action: PayloadAction<Record<string, any>>) => {
			state.firestoreFlexposProducts = action.payload;
		},
		reset: () => {
			return initialState;
		},
	},
});

export const selectShippingInfo = (state): IShippingInfo => state.shippingSlice.shippingInfo;
export const selectError = (state) => state.shippingSlice.error;
export const selectJournalUid = (state) => state.shippingSlice.journalUid;
export const selectSelectedShippingMethod = (state) => state.shippingSlice.selectedShippingMethod;
export const selectSelectedDropPoint = (state) => state.shippingSlice.selectedDropPoint;
export const selectJournalLines = (state): Array<ParsedProductLineIncludingDiscount> =>
	state.shippingSlice.journalLines;
export const selectLoading = (state) => state.shippingSlice.loading;
export const selectStock = (state) => state.shippingSlice.stock;
export const selectOrderRef = (state) => state.shippingSlice.orderRef;
export const selectOrderForUse = (state) => state.shippingSlice.orderForUse;
export const selectActiveStep = (state) => state.shippingSlice.activeStep;
export const selectFirestoreFlexposProducts = (state) => state.shippingSlice.firestoreFlexposProducts;
export const selectDebug = (state) => state.shippingSlice.debug;
export const selectWriteToPOS = (state) => state.shippingSlice.writeToPos;
export const selectState = (state) => state.shippingSlice;

export const {
	setJournalUid,
	setShippingInfoFormData,
	setShippingMethod,
	setDropPoint,
	setJournalLines,
	setNowAmount,
	setAfterAmount,
	switchAmount,
	setActiveStep,
	setStock,
	setShippingInfoFromFlexposCustomer,
	setOrderRef,
	setOrderForUse,
	setWriteToPOS,
	setDoneWriteToPOS,
	setFirestoreFlexposProducts,
	reset,
	setStockByProductNumber,
} = shippingSlice.actions;

export default shippingSlice.reducer;
