import React from 'react';

const FlexPosPage = React.lazy(() => import('./FlexposPage'));

const FlexposConfig = {
	routes: [
		{
			path: '/',
			element: <FlexPosPage />,
		},
	],
};

export default FlexposConfig;
