import firebaseService from '@ameroservices-platform/shared/services/firebase';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import { setUserDataFirebase, setUserData, logoutUser } from './store/userSlice';
import { setSettings } from '../store/shared/frontendSlice';
import FuseSplashScreen from '@ameroservices-platform/shipfromstore-flexposui/fuse/core/FuseSplashScreen';
import { hideMessage, showMessage } from '@ameroservices-platform/shipfromstore-flexposui/app/store/fuse/messageSlice';
import organisationType from '@ameroservices-platform/shared/enums/organisationType';
import { getParameterByName } from '@ameroservices-platform/shared/utility/index';

class Auth extends Component {
	state = {
		waitAuthCheck: true, // Was TRUE!!
	};

	componentDidMount() {
		firebaseService.init(
			(success) => {
				console.log('init', success);
				if (!success) {
					return Promise.resolve();
				}
				return Promise.resolve()
					.then(this.getConfig)
					.then(this.firebaseCheck)
					.then(() => {
						this.setState({ waitAuthCheck: false });
						if(document.querySelector('#tempLoading')){
							document.querySelector('#tempLoading').remove();
						}
					});
			},
			null,
			organisationType.SHIP_FROM_STORE
		);
	}

	getConfig = () =>
		new Promise((resolve) => {
			fetch(
				'/siteConfig' +
					(getParameterByName('organisationUid')
						? '?organisationUid=' + getParameterByName('organisationUid')
						: '')
			)
				.then(async (response) => {
					const config = await response.json();
					console.log('siteConfig', config, config.organisationId, config.tenantId);

					firebaseService.setOrganisationId(config.organisationId);
					firebaseService.setTenantId(config.tenantId);
					firebaseService.setSignedSearchKey(config.signedSearchKey);
					this.props.setSettings(config);
					moment.tz.setDefault(config.timezone || 'Europe/Copenhagen');
					console.log(`moment timezone set to ${config.timezone}`);
					resolve();
				})
				.catch((e) => {
					// eslint-disable-next-line no-alert
					console.error('Site not configured!', e);

					resolve();
				});

			return Promise.resolve();
		});

	firebaseCheck = () =>
		new Promise((resolve) => {
			firebaseService.onAuthStateChanged((authUser) => {
				if (authUser) {
					// this.props.showMessage({ message: 'Logger på...' });

					/**
					 * Retrieve user data from Firebase
					 */
					firebaseService.getUserData(authUser.uid).then(
						(user) => {
							if (!user.claims.organisationIds) {
								firebaseService
									.callFunctionByName('authSetOrganisationOnTenantUser')
									.then(() => {
										firebaseService.refreshToken();
									})
									.catch((e) => {
										console.error(e);
									});
							}
							this.props.setUserDataFirebase(user, authUser);
							// firebaseService.setSignedSearchKey(
							// 	user.claims.signedSearchKeys &&
							// 		user.claims.signedSearchKeys[
							// 			firebaseService.getOrganisationType()
							// 		]
							// 		? user.claims.signedSearchKeys[
							// 				firebaseService.getOrganisationType()
							// 		  ]
							// 		: user.claims.signedSearchKey
							// );

							resolve();
							this.props.hideMessage();
							// this.props.showMessage({ message: 'Du er nu logget ind' });
						},
						(error) => {
							resolve();
						}
					);
				} else {
					firebaseService.auth.signInAnonymously().then(
						(authEvent) => {
							resolve();
							this.props.hideMessage();
							// this.props.showMessage({ message: 'Du er nu logget ind' });
						},
						(error) => {
							resolve();
						}
					);
				}
			});

			return Promise.resolve();
		});

	render() {
		if (this.state.waitAuthCheck) {
			return <FuseSplashScreen />;
		}
		return this.props.children;
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			logout: logoutUser,
			setUserData,
			setUserDataFirebase,
			showMessage,
			hideMessage,
			setSettings,
		},
		dispatch
	);
}

export default connect(null, mapDispatchToProps)(Auth);
